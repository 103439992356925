import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";
import Quote from "./Quote";

import { ThreadSpawn, ThreadScopedScope, ThreadScopedSpawn } from "./Excerpts";
import { ChannelQuote } from "./Quotes";

export const title = "Fearless concurrency";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Fearless concurrency

---

# Threads

Rust provides access to native threads via `std::thread`.

---

# `thread::spawn`

Provides a simpler entrypoint to start a thread.

<ThreadSpawn />

<Notes />

- Ignore `Send` and `'static` for right now, we will cover those in a second

<Continue />

import threadNoJoin from "rust:concurrency/thread-no-join";

<Transclude src={threadNoJoin} />

<Notes />

- What do you expect to happen when we try to compile and run this?

---

# `thread::spawn`

```compiler-output

```

<Notes />

- Yes, actually nothing

<Continue />

```compiler-output
Hello from another thread!
```

<Notes />

- If you are very (un)lucky

---

# Threads can be detached

<Notes />

- TODO: discuss why useful

---

# Threads can be joined

import threadWithJoin from "rust:concurrency/thread-with-join";

<Transclude src={threadWithJoin} emphasize="7" />

<Notes />

- You can call `join` on the returned handle to wait for the thread to finish.
- If a thread panics, the result of `join` will be an error

---

# Referencing a variable

import referenceVariable from "rust:concurrency/reference-variable";

<Transclude src={referenceVariable} />

<Continue />

import referenceVariableStderr from "stderr:concurrency/reference-variable";

<Transclude
  lang="compiler-error"
  src={referenceVariableStderr}
  focusOn="1+14"
/>

---

# `move` closures

<Transclude
  lang="compiler-error"
  src={referenceVariableStderr}
  focusOn="15+5"
/>

---

# Closures capture by inspecting usage...

import closureCapture from "rust:concurrency/closure-capture";

<Transclude src={closureCapture} />

<Notes />

- Note that the syntax for all the closures is the same
- Compare to C++ where you have to be more explicit about how and what is captured

---

# ... which is _almost_ always right

It isn't what we want when a value is only used by reference but we
want the closure to own the value regardless.

---

# `move` closures force ownership to be transferred

import closureCaptureRev1 from "rust:concurrency/closure-capture?rev=1";

<Transclude src={closureCaptureRev1} emphasize="16[13+4],20[13+4]" />

---

# Referencing a variable

import referenceVariableRev1 from "rust:concurrency/reference-variable?rev=1";

<Transclude src={referenceVariableRev1} emphasize="6[18+4]" />

<Continue />

import referenceVariableRev1Stdout from "stdout:concurrency/reference-variable?rev=1";

<Transclude lang="compiler-output" src={referenceVariableRev1Stdout} />

---

# Mutating a variable

import mutateVariable from "rust:concurrency/mutate-variable";

<Transclude src={mutateVariable} focusOn="4+12" />

<Continue />

import mutateVariableStderr from "stderr:concurrency/mutate-variable";

<Transclude lang="compiler-error" src={mutateVariableStderr} focusOn="1+14" />

---

# Shared ownership

- `Rc`
- `Arc`

<Notes />

- Guarantees and tradeoffs
- Single vs multiple threads

---

# Makes non-cloneable types cloneable

import sharedOwnershipClone from "rust:concurrency/shared-ownership-clone";

<Transclude src={sharedOwnershipClone} />

<Continue />

import sharedOwnershipCloneStderr from "stderr:concurrency/shared-ownership-clone";

<Transclude
  lang="compiler-error"
  src={sharedOwnershipCloneStderr}
  focusOn="1+12"
/>

---

# Makes non-cloneable types cloneable...

import sharedOwnershipCloneRev1 from "rust:concurrency/shared-ownership-clone?rev=1";

<Transclude src={sharedOwnershipCloneRev1} />

<Continue />

import sharedOwnershipCloneRev1Stdout from "stdout:concurrency/shared-ownership-clone?rev=1";

<Transclude lang="compiler-output" src={sharedOwnershipCloneRev1Stdout} />

---

# ... but ...

import sharedOwnershipImmutable from "rust:concurrency/shared-ownership-immutable";

<Transclude src={sharedOwnershipImmutable} />

<Continue />

# ... no longer mutable

import sharedOwnershipImmutableStderr from "stderr:concurrency/shared-ownership-immutable";

<Transclude
  lang="compiler-error"
  src={sharedOwnershipImmutableStderr}
  focusOn="11+8"
/>

---

# Interior mutability

- `Cell`
- `RefCell`
- `Atomic*`
- `Mutex`
- `RwLock`

<Notes />

- Guarantees and tradeoffs

  - `Copy` vs not
  - Single vs multiple threads
  - Fairness

- Works by moving borrow checking to runtime, not compile time

---

# Interior mutability

<Notes />

- What's the simplest way we can prevent two mutable references?

<Continue />

import interiorMutability from "rust:concurrency/interior-mutability";

<Transclude src={interiorMutability} />

<Notes />

- What's the simplest way we can prevent two mutable references while allowing references?

<Continue />

import interiorMutabilityRev1 from "rust:concurrency/interior-mutability?rev=1";

<Transclude src={interiorMutabilityRev1} />

---

# Applying shared ownership and interior mutability

import appliedSharedOwnershipInteriorMutability from "rust:concurrency/applied-shared-ownership-interior-mutability";

<Transclude
  src={appliedSharedOwnershipInteriorMutability}
  focusOn="7+13"
  emphasize="1[13+30]"
/>

<Notes />

- We choose `Arc` and `Mutex`
- `Arc` and `Mutex` are needed for multithreading
- `Mutex` is a reasonable default

---

# Applying shared ownership and interior mutability

<Transclude
  src={appliedSharedOwnershipInteriorMutability}
  focusOn="7+13"
  emphasize="3[26+8]"
/>

<Notes />

- Create a shared copy to give to the thread

---

# Applying shared ownership and interior mutability

<Transclude
  src={appliedSharedOwnershipInteriorMutability}
  focusOn="7+13"
  emphasize="6[17+31],10[6+31]"
/>

<Notes />

- Move borrow checking to runtime, which additionally can fail.

---

# Applying shared ownership and interior mutability

<Transclude src={appliedSharedOwnershipInteriorMutability} focusOn="7+13" />

<Notes />

- Will this compile? What will it output?

<Continue />

```compiler-output
Scores A: Mutex { data: [42], poisoned: false, .. }
Scores B: Mutex { data: [42, 2, 1], poisoned: false, .. }
Scores C: Mutex { data: [42, 2, 1], poisoned: false, .. }
```

<Continue />

```compiler-output
Scores C: Mutex { data: [42, 2], poisoned: false, .. }
Scores A: Mutex { data: [42, 2], poisoned: false, .. }
Scores B: Mutex { data: [42, 2, 1], poisoned: false, .. }
```

<Continue />

```compiler-output
Scores A: Mutex { data: [42], poisoned: false, .. }
Scores C: Mutex { data: [42, 2], poisoned: false, .. }
Scores B: Mutex { data: [42, 2, 1], poisoned: false, .. }
```

<Notes />

- While Rust prevents data races, it doesn't enforce logical ordering (race conditions)

---

import exerciseThreads from "rust:concurrency/exercise-threads";

# Exercise: Adding up numbers in parallel

- Given a large vector of numbers, add up all the numbers in parallel
- Each thread adds up a different section of the vector
- Then sum up the results from the threads
- Code like this should run:

  <Transclude src={exerciseThreads} focusOn="25+2" />

## Bonus

- Instead of returning values from the threads, write to a shared total count

---

# One answer

<Continue />

<Transclude src={exerciseThreads} />

---

# Sharing values while the thread is running

<ChannelQuote />

---

# What are channels good for?

- Communicating between concurrent workers
  - Different types can be sent through a channel
- Avoiding lower level primitives
  - mutex
  - condition variable

<Notes />

- Notably _while_ they are still running

---

# Using channels

import channels from "rust:concurrency/channels";

<Transclude src={channels} />

---

# Using channels

<Transclude src={channels} emphasize="1[10+10]" />

<Notes />

- Importing the channel module

---

# Using channels

<Transclude src={channels} emphasize="4" />

<Notes />

- Creating a channel
- Pair of sender and receiver
- This is an _unbounded_ channel, there's also bounded ones

---

# Using channels

<Transclude src={channels} emphasize="8" />

<Notes />

- Sending a value can fail

---

# Using channels

<Transclude src={channels} emphasize="13" />

<Notes />

- A receiver can be treated as an iterator
- Iterator ends when all the senders have been dropped or an error occurs
- It also has methods to allow timeouts

---

# Using channels

import channelsStdout from "stdout:concurrency/channels";

<Transclude lang="compiler-output" src={channelsStdout} />

<Notes />

- Output from the channel is received in the same order it was added

---

# Multi Producer, Single Consumer

import channelsMpsc from "rust:concurrency/channels-mpsc";

<Transclude src={channelsMpsc} />

---

# Multi Producer, Single Consumer

<Transclude src={channelsMpsc} emphasize="8" />

---

# Multi Producer, Single Consumer

<Transclude src={channelsMpsc} emphasize="16" />

---

# Multi Producer, Single Consumer

import channelsMpscStdout from "stdout:concurrency/channels-mpsc";

<Transclude lang="compiler-output" src={channelsMpscStdout} focusOn="1+28" />

<Notes />

- Output from the channel is received in the same order it was added
- No ordering between the producers

---

# Avoiding locks with channels

import channelsMutability from "rust:concurrency/channels-mutability";

<Transclude src={channelsMutability} focusOn="3+27" />

---

# Avoiding locks with channels

<Transclude src={channelsMutability} focusOn="3+27" emphasize="8" />

<Notes />

- Multiple threads are able to send data in.

---

# Avoiding locks with channels

<Transclude src={channelsMutability} focusOn="3+27" emphasize="15" />

<Notes />

- One thread takes ownership of the `Vec`
- Allowed to mutate because there's one owner

---

# Avoiding locks with channels

<Transclude src={channelsMutability} focusOn="3+27" emphasize="19" />

<Notes />

- Returning a value when the thread is done
- Could also have its own channel to send along

---

import exerciseChannels from "rust:concurrency/exercise-channels";

# Exercise: Adding up numbers using channels

- Each thread adds up a different section of the vector
- Send the partial sum for every ten numbers summed up
- Code like this should run:

  <Transclude src={exerciseChannels} focusOn="38+2" />

# Hints

- Ensure unused sending half(s) are dropped

---

# One answer

<Continue />

<Transclude src={exerciseChannels} focusOn="6+29" />

<Notes />

- `.fold(0, |sum, x| dbg!(sum + x))` to see the running total

---

# The `'static` bound

<Transclude
  lang="compiler-error"
  src={referenceVariableStderr}
  focusOn="10+1"
  emphasize="1[50+7]"
/>

<Continue />

<ThreadSpawn emphasize="4[14+7],5[14+7]" />

---

# Lifetime bounds

- any references in the type must be valid that long
- `'static` means "lives the entire length of the program"
- If no references, meets any bound

---

# `'static` prevents memory unsafety

import parentThreadStackReference from "rust:concurrency/parent-thread-stack-reference";

<Transclude src={parentThreadStackReference} />

<Notes />

- Since thread can be detached, no guarantee that the parent thread (and the variable) will be available when the thread runs.
- `'static` is conservative for `thread::spawn`

---

# What if the child thread exited first?

- Known as "scoped threads"
- Available in the standard library since Rust 1.63
- Choices also available in the crate ecosystem

<Notes />

- Was available before 1.0, but had unsafety

---

# Scoped threads

<ThreadScopedScope />

<ThreadScopedSpawn />

---

# Scoped threads

import scopedThreads from "rust:concurrency/scoped-threads";

<Transclude src={scopedThreads} focusOn="3+9" />

<Notes />

- Will this compile?
- Different signature to enforce that the child thread exits first

<Continue />

import scopedThreadsStdout from "stdout:concurrency/scoped-threads";

<Transclude lang="compiler-output" src={scopedThreadsStdout} />

---

# Scoped threads

import scopedThreadsRev1 from "rust:concurrency/scoped-threads?rev=1";

<Transclude src={scopedThreadsRev1} focusOn="3+12" emphasize="2[8+3],7" />

<Notes />

- Will this compile?

<Continue />

import scopedThreadsRev1Stdout from "stdout:concurrency/scoped-threads?rev=1";

<Transclude lang="compiler-output" src={scopedThreadsRev1Stdout} />

---

# Scoped threads

import scopedThreadsRev2 from "rust:concurrency/scoped-threads?rev=2";

<Transclude src={scopedThreadsRev2} focusOn="3+10" emphasize="6" />

<Notes />

- Will this compile?

<Continue />

import scopedThreadsRev2Stderr from "stderr:concurrency/scoped-threads?rev=2";

<Transclude
  lang="compiler-error"
  src={scopedThreadsRev2Stderr}
  focusOn="1+15"
/>

---

import exerciseScopedThreads from "rust:concurrency/exercise-scoped-threads";

# Exercise: Adding up numbers using scoped threads

- Each thread adds up a different section of the vector
- Then sum up the results from the threads
- No need to use `Arc`
- Code like this should run:

  <Transclude src={exerciseScopedThreads} focusOn="18+2" />

---

# One answer

<Continue />

<Transclude src={exerciseScopedThreads} />

---

# `Send`

### Regular threads

<ThreadSpawn emphasize="4[7+4],5[7+4]" />

### Scoped threads

<ThreadScopedSpawn emphasize="4[27+4],5[11+4]" />

---

# `Send`

Types that can be transferred across thread boundaries.

# `Sync`

Types for which it is safe to share references between threads.

<Notes />

- Automatically implemented for a type if all components implement it.

---

# Types that are not `Send` or `Sync`

## Not `Send`

- `Rc`

## Not `Sync`

- `Cell`, `RefCell`

---

# Compiler prevents misuse

import sendSyncMisuse from "rust:concurrency/send-sync-misuse";

<Transclude src={sendSyncMisuse} />

---

# Compiler prevents misuse

import sendSyncMisuseStderr from "stderr:concurrency/send-sync-misuse";

<Transclude lang="compiler-error" src={sendSyncMisuseStderr} focusOn="1+28" />

---

# Pieces of the landscape

## Language

- `Send`
- `Sync`

## Standard library

- OS threads
- Scoped threads
- `Arc` / `Mutex` / MPSC channels

## Crates

- Scoped threads
- Parallel iterators

---

# Rayon

<Quote caption="Rayon documentation" href="https://crates.io/crates/rayon">
  Rayon is a data-parallelism library for Rust. It is extremely lightweight and
  makes it easy to convert a sequential computation into a parallel one. It also
  guarantees data-race freedom.
</Quote>

## Add as a dependency

import rayonToml from "toml:concurrency/rayon";

<Transclude lang="toml" src={rayonToml} focusOn="8-9" />

---

# Rayon

import rayon from "rust:concurrency/rayon";

<Transclude src={rayon} />

<Notes />

- Will this compile and what will it print?

<Continue />

import rayonStdout from "stdout:concurrency/rayon";

<Transclude lang="compiler-output" src={rayonStdout} />

---

import exerciseRayon from "rust:concurrency/exercise-rayon";

# Exercise: Adding up numbers using parallel iterators

- Code like this should run:

  <Transclude src={exerciseRayon} focusOn="8+2" />

## Hint

- Implementation of `adder` is one expression

---

# One answer

<Continue />

<Transclude src={exerciseRayon} />

---

# And now for something completely different...

- Threads are great for:
  - parallelism
  - CPU-intensive work
- Not needed for:
  - concurrency
  - IO-intensive work

---

# A _promise_ of a brighter _future_

- Rust has an established ecosystem using futures
- Rust 1.36 added `Future` to the standard library
- Rust 1.39 introduced `async` / `await` syntax

<Notes />

- Pedantically, promises and futures are subtly different, but for many purposes that doesn't matter

---

# Futures in Rust 1.36

import futuresOld from "rust:concurrency/futures-old";

<Transclude src={futuresOld} focusOn="1+24" />

---

# Futures in Rust 1.36

<Transclude src={futuresOld} focusOn="1+24" emphasize="3[24+28]" />

---

# Futures in Rust 1.36

<Transclude src={futuresOld} focusOn="1+24" emphasize="4[4+17],4[40+1]" />

---

# Futures in Rust 1.36

<Transclude
  src={futuresOld}
  focusOn="1+24"
  emphasize="11[8+5],11[56+4],13[15+12],14[8+4]"
/>

---

# Futures in... the present

import futuresNew from "rust:concurrency/futures-new";

<Transclude src={futuresNew} focusOn="1+27" />

---

# Futures in... the present

<Transclude src={futuresNew} focusOn="1+27" emphasize="3" />

---

# Futures in... the present

<Transclude src={futuresNew} focusOn="1+27" emphasize="4" />

---

# Futures in... the present

<Transclude src={futuresNew} focusOn="1+27" emphasize="10[25+5]" />

---

# Futures in... the present

<Transclude
  src={futuresNew}
  focusOn="1+27"
  emphasize="11[36+6],12[55+6],17[40+6]"
/>

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
