import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";

import * as styles from "./ownership.module.css";

export const title = "Ownership and borrowing";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Ownership and borrowing

---

# Ownership

import basic from "rust:ownership/basic";

<Transclude src={basic} focusOn="1+3" />

<Notes />

- Owner controls mutability
- When a value has no owner, it's cleaned up at the end of scope

---

# Borrowing

import borrowing from "rust:ownership/borrowing";

<Transclude src={borrowing} focusOn="2+2" />

---

# Borrowing

<Transclude src={borrowing} focusOn="2+2" emphasize="2[31+1]" />

<Notes />

- All thanks to the humble ampersand

---

import valueAnalogy from "./assets/ownership/value.png";

# Ownership and borrowing

<Transclude src={borrowing} focusOn="2+2" />

<img className={styles.chunkyImage} src={valueAnalogy} alt="value analogy" />

<Notes />

- `knowledge` is something we don't want to duplicate needlessly
- Maybe it takes a lot of memory?

---

import referenceAnalogy from "./assets/ownership/reference.png";

# Ownership and borrowing

<Transclude src={borrowing} focusOn="2+2" />

<img
  className={styles.chunkyImage}
  src={referenceAnalogy}
  alt="reference analogy"
/>

<Notes />

- `a_reference_to_knowledge` points to the same memory
- It's not a copy, so it's very lightweight

---

# Borrowing

## Immutable

import borrowKinds from "rust:ownership/borrow-kinds";

<Transclude src={borrowKinds} focusOn="2+2" emphasize="2[13+1]" />

<Continue />

## Mutable

<Transclude src={borrowKinds} focusOn="5+2" emphasize="1[4+3],2[13+4]" />

<Notes />

- Requires that the variable be mutable

---

# Rules of references

- Either
  - one mutable reference
  - any number of immutable references
- References must always be valid

---

# Immutable and mutable borrowing

import immutableWhileMutable from "rust:ownership/immutable-while-mutable";
import immutableWhileMutableStderr from "stderr:ownership/immutable-while-mutable";

<Transclude src={immutableWhileMutable} />
<Transclude
  lang="compiler-error"
  src={immutableWhileMutableStderr}
  focusOn="1+10"
/>

<Notes />

- If you have a mutable reference, no other references of any kind
- If you have immutable references, as many as you want
- Definitely cannot have both at the same time

---

# You've been borrowing all along

import methodImplicitBorrow from "rust:ownership/method-implicit-borrow";

<Transclude src={methodImplicitBorrow} />

<Continue />

## Equivalent

import methodImplicitBorrowRev1 from "rust:ownership/method-implicit-borrow?rev=1";

<Transclude src={methodImplicitBorrowRev1} focusOn="11+5" />

<Notes />

- The compiler automatically borrows for you on method calls

---

# Slices

import slices from "rust:ownership/slices";

<Transclude src={slices} focusOn="2+2" />

<Notes />

- Special kind of reference

---

# Slices

import vecMemory from "./assets/ownership/vec.png";

<img className={styles.chunkyImage} src={vecMemory} alt="memory of Vec" />

---

# Slices

import sliceMemory from "./assets/ownership/slice.png";

<img className={styles.chunkyImage} src={sliceMemory} alt="memory of slice" />

<Notes />

- Points to the start of some data and knows how many items there are
- Not _that_ unique, but the usability and safety comes from borrowing

---

# String slices

import stringSlices from "rust:ownership/string-slices";

<Transclude src={stringSlices} focusOn="2+2" />

<Notes />

- Special kind of slice
- Always guaranteed to be UTF-8

---

# String slices

import stringMemory from "./assets/ownership/string.png";

<img className={styles.chunkyImage} src={stringMemory} alt="memory of String" />

---

# String slices

import stringSliceMemory from "./assets/ownership/string-slice.png";

<img
  className={styles.chunkyImage}
  src={stringSliceMemory}
  alt="memory of &str"
/>

---

import strings from "rust:ownership/strings";

# Exercise: Strings

- Create a function that accepts a string
  - return that string wrapped in parenthesis
- Code like this should run:

  <Transclude src={strings} focusOn="6+1" />

---

# One answer

<Continue />

<Transclude src={strings} />

<Notes />

- Any disquiet about this solution?

---

# Aside: Deref coercions

- `String`, `Vec` and other smart pointers implement `Deref`
  - `&String` can be treated as `&str`
  - `&Vec<T>` can be treated as `&[T]`
  - `&Box<T>` can be treated as `&T`

---

# Aside: Deref coercions

import derefCoercion from "rust:ownership/deref-coercion";

<Transclude src={derefCoercion} />

---

# The borrow checker

import borrowChecker from "rust:ownership/borrow-checker";
import borrowCheckerStderr from "stderr:ownership/borrow-checker";

<Transclude src={borrowChecker} focusOn="2+4" />

<Transclude lang="compiler-error" src={borrowCheckerStderr} focusOn="1+11" />

<Notes />

- We take a reference to the same book, but it is destroyed before we use it
- This is caught at compile time
- No dangling pointers (and all the security holes)
- Many people refer to this as "fighting" the borrow checker

---

# Move semantics

import moveSemantics from "rust:ownership/move-semantics";
import moveSemanticsStderr from "stderr:ownership/move-semantics";

<Transclude src={moveSemantics} />
<Transclude lang="compiler-error" src={moveSemanticsStderr} focusOn="1+15" />

---

# Move semantics

import moveSemanticsRev1 from "rust:ownership/move-semantics?rev=1";
import moveSemanticsRev1Stderr from "stderr:ownership/move-semantics?rev=1";

<Transclude src={moveSemanticsRev1} />
<Transclude
  lang="compiler-error"
  src={moveSemanticsRev1Stderr}
  focusOn="1+11"
/>

<Notes />

- The previous example (being dropped) was a special case of a move

---

# Copy

- A fundamental trait
- Indicates that it's safe and cheap to perform a bitwise copy
- Compiler will automatically insert these copies as needed
- Can only be implemented if all members are `Copy`

---

# Copy

import copy from "rust:ownership/copy";

<Transclude src={copy} focusOn="2+3" />

<Notes />

- Integers implement `Copy`, so this code will compile fine

---

# Clone

- Indicates that it's possible to perform a copy
- Implementor determines what the behavior is
- Usually derive `Clone` which just clones all fields
- Explicit method call is required
- **PROTIP**: No shame in using `Clone`
  - or any other ownership

---

# Clone

import clone from "rust:ownership/clone";

<Transclude src={clone} focusOn="2+3" emphasize="2[25+8]" />

<Notes />

- Make a clone before moving it
- `a_book` is still valid

---

# Lifetimes

<Notes />

- Ask what people think "lifetime" means

<Continue />

- The region of the code where a value exists at a specific address
- Common misconception: time between when a value is created and destroyed

<Notes />

- Maybe Rust picked the wrong term here

---

# My favorite lifetime analogy

- A value is your home
- Moving changes your address
- Dying also changes your address

<Continue />

- A reference is a piece of paper with your address
- When you use a reference, you go to the address

<Continue />

- Walk in the door without asking and start poking around

<Notes />

- Breaks down a bit here

---

# Concrete lifetimes

import concreteLifetimes from "rust:ownership/concrete-lifetimes";

<Transclude src={concreteLifetimes} />

---

# Generic lifetimes

import genericLifetimes from "rust:ownership/generic-lifetimes";

<Transclude
  src={genericLifetimes}
  focusOn="2+6"
  emphasize="1[25+2],1[36+2],1[48+2]"
/>

<Notes />

- Lifetimes go hand-in-hand with the borrow checker
- Generic lifetimes let us connect input and output references

---

import shorterString from "rust:ownership/shorter-string";

# Exercise: Shorter string

- Write a function that takes two string slices
- Returns the shorter string (compared via `len`)
  - Pick whichever when equal length
- Code like this should run:

  <Transclude src={shorterString} focusOn="10+1" />

---

# One answer

<Continue />

<Transclude src={shorterString} />

---

# Differing lifetimes

import differingLifetimes from "rust:ownership/differing-lifetimes";

<Transclude src={differingLifetimes} />

<Notes />

- Will this compile?
- If so, where would it be valid to declare `shorter`?

---

# Lifetimes are unified

<Transclude
  src={differingLifetimes}
  emphasize="6[57+1],7[57+1],8[57+1],9[57+1],10[57+1]"
/>

- When two concrete lifetimes share a generic lifetime, <br/> the intersection of them is chosen.

---

import uniqueLines from "rust:ownership/unique-lines";

# Exercise: Count the unique lines in a file

- Create a function that takes a path to a file and returns a `HashMap`
- Code like this should run:

  <Transclude src={uniqueLines} focusOn="16+4" />

## Hints

- `std::collections::HashMap::entry`
- `std::fs::read_to_string`
- Return a `HashMap<..., usize>`

## Bonus

- Do it for words instead of lines
- Do it without reading the entire file at once

---

# One answer

<Continue />

<Transclude src={uniqueLines} />

---

# Lifetime elision

- Each reference parameter gets a unique lifetime parameter

  ```rust
  fn bar(x: &i32, y: &i32)
  fn bar<'a, 'b>(x: &'a i32, y: &'b i32)
  ```

- If only one reference parameter, assign to all output lifetimes

  ```rust
  fn foo(x: &i32) -> &i32
  fn foo<'a>(x: &'a i32) -> &'a i32
  ```

- If `&self` or `&mut self`, assign to all output lifetimes

  ```rust
  fn quux(&self, x: &i32) -> &i32
  fn quux<'a, 'b>(&'a self, x: &'b i32) -> &'a i32
  ```

---

# Elision may be confusing

- Especially when a lifetime parameter is inside of another type:

  ```rust
  pub fn iter(&self) -> Iter<T>
  ```

  ```rust
  pub fn iter<'a>(&'a self) -> Iter<'a, T>
  ```

  ```rust
  pub fn iter(&self) -> Iter<'_, T>
  ```

- Opt in to a warning when elision occurs in this case

  ```rust
  #![deny(rust_2018_idioms)]
  ```

---

# Cleaning up a value

- A type defines what to do when it is cleaned up
- Provided by compiler if not explicit
- The destructor of a type is automatically called when no owner
- Leaking memory is **safe**

---

# Implementing `Drop`

import dropOrder from "rust:ownership/drop-order";

<Transclude src={dropOrder} focusOn="1+7" />

---

# Drop order

<Transclude src={dropOrder} focusOn="10+2" />

<Continue />

import dropOrderStdout from "stdout:ownership/drop-order";

<Transclude lang="compiler-output" src={dropOrderStdout} />

---

import dropOrderRev1 from "rust:ownership/drop-order?rev=1";
import dropOrderRev1Stdout from "stdout:ownership/drop-order?rev=1";

# Drop order

<Transclude src={dropOrderRev1} focusOn="10+2" />

<Continue />

<Transclude lang="compiler-output" src={dropOrderRev1Stdout} />

<Notes />

- Variables are dropped in reverse declaration order

---

import dropOrderRev2 from "rust:ownership/drop-order?rev=2";
import dropOrderRev2Stdout from "stdout:ownership/drop-order?rev=2";

# Drop order

<Transclude src={dropOrderRev2} focusOn="10+4" />

<Continue />

<Transclude lang="compiler-output" src={dropOrderRev2Stdout} />

<Notes />

- Not often needed, but good to know about

---

# Shared ownership

- Sometimes need to model multiple owners of a single value
- Specific types provide this ability
  - `Rc`: reference-counted
  - `Arc`: atomically reference-counted
- Slightly heavier than a reference, performs runtime checks
- May be lighter than a "real" clone
- Allows cloning the unclonable

<Notes />

- TODO: x-ref with concurrency

---

# Using `Rc` for shared ownership

import sharedOwnership from "rust:ownership/shared-ownership";

<Transclude src={sharedOwnership} />

---

# Disjoint borrows

import disjointBorrows from "rust:ownership/disjoint-borrows";

<Transclude src={disjointBorrows} focusOn="1+12" />

<Notes />

- This works fine

---

# Disjoint borrows

import disjointBorrowsRev1 from "rust:ownership/disjoint-borrows?rev=1";

<Transclude src={disjointBorrowsRev1} focusOn="6+15" />

<Notes />

- You refactor a bit...
- Copy-paste error: `self.a` in both functions

<Continue />

import disjointBorrowsRev1Stderr from "stderr:ownership/disjoint-borrows?rev=1";

<Transclude
  lang="compiler-error"
  src={disjointBorrowsRev1Stderr}
  focusOn="1+12"
/>

<Notes />

- And bam, error

---

# Function signatures are the key

- Rust only cares about the function signature
- When a function is compiled, it's
  - checked against signatures of functions it calls
  - checked against its signature

<Notes />

- The signature is part of your public API
- Makes reasoning about the code simpler

---

# Function signatures are the key

import disjointBorrowsRev2 from "rust:ownership/disjoint-borrows?rev=2";

<Transclude src={disjointBorrowsRev2} focusOn="1+28" />

---

# Either owned or borrowed

import ownedOrBorrowed from "rust:ownership/owned-or-borrowed";

<Transclude src={ownedOrBorrowed} />

<Notes />

- Non-optimal when we don't need to add anything

---

# Clone-on-write 🐮

- Holds either a reference or an owned value
- Both types are related
- When mutation is needed, can "upgrade" to an owned value

---

# Using a `Cow`

import cow from "rust:ownership/cow";

<Transclude src={cow} />

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
