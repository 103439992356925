import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";

import {
  SliceSplitAtMut,
  SliceSplitAtMutUnchecked,
  SliceFromRawParts,
  SliceFromRawPartsMut,
} from "./Excerpts";

export const title = "Unsafe code and interfacing with C";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Unsafe code and <br/> interfacing with C

---

# The tale of two languages

- Rust can be thought of as two related languages
  - Safe Rust
  - Unsafe Rust

---

# What can you do in unsafe Rust?

- Dereference a raw pointer
- Call an unsafe function
- Implement an unsafe trait
- Read or write a mutable static variable
- Read a field of a union

---

# You still can't do unsafe things

- The compiler just can't ensure the safety

---

# Raw pointers

- References (`&T` / `&mut T`)
  - Are never `NULL`
  - Always point to a valid value
  - Have lifetimes

<Continue />

- Raw pointers (`*const T` / `*mut T`)
  - Can be `NULL`
  - Can point anywhere
  - Do not have lifetimes

---

# Dereferencing a raw pointer

import dereferenceRawPointer from "rust:ffi/dereference-raw-pointer";

<Transclude src={dereferenceRawPointer} />

<Continue />

import dereferenceRawPointerStderr from "stderr:ffi/dereference-raw-pointer";

<Transclude
  src={dereferenceRawPointerStderr}
  lang="compiler-error"
  focusOn="1+8"
/>

---

# Dereferencing a raw pointer

import dereferenceRawPointerRev1 from "rust:ffi/dereference-raw-pointer?rev=1";

<Transclude src={dereferenceRawPointerRev1} emphasize="5[19+8],5[39+1]" />

<Continue />

## Creating a reference

import dereferenceRawPointerRev2 from "rust:ffi/dereference-raw-pointer?rev=2";

<Transclude src={dereferenceRawPointerRev2} emphasize="5[37+2]" />

---

# A note on using `unsafe` blocks

- Document why _this_ unsafe block is safe
- Show your preconditions and how you uphold them

<Notes />

- Debate on whether preconditions go inside the `unsafe` block or not
- Debate on how big `unsafe` blocks should be

---

# Calling an unsafe function

import callUnsafeFn from "rust:ffi/call-unsafe-fn";

<Transclude src={callUnsafeFn} />

---

# Calling an unsafe function

<Transclude src={callUnsafeFn} emphasize="1[0+6]" />

<Notes />

- We cannot guarantee that this function is safe for any arbitrary pointer

---

# Unsafe code is used to build safe abstractions

- `Vec` uses unsafe code internally
- You don't need unsafe code to use `Vec`
- Type and module systems keep unsafe code contained
- Safe Rust should never cause undefined behavior

---

# Why is unsafe Rust needed?

import whyUnsafe from "rust:ffi/why-unsafe";

<Transclude src={whyUnsafe} />

<Continue />

import whyUnsafeStderr from "stderr:ffi/why-unsafe";

<Transclude src={whyUnsafeStderr} lang="compiler-error" focusOn="1+10" />

<Notes />

- `[]` syntax is provided by a trait.
- no guarantee that it returns different values

---

# The pathological case

import pathological from "rust:ffi/pathological";

<Transclude src={pathological} />

---

# `slice::split_at_mut`

<Transclude src={whyUnsafeStderr} lang="compiler-error" focusOn="11+3" />

<Continue />

import splitAtMut from "rust:ffi/split-at-mut";

<Transclude src={splitAtMut} />

<Notes />

- Will this compile?

---

# `slice::split_at_mut`

<SliceSplitAtMut />
<SliceSplitAtMutUnchecked />

---

# Exercise: Our own strange indexing

import exerciseStrangeIndexing from "rust:ffi/exercise-strange-indexing";

- Create a function that is given a mutable slice and an index
- Return a tuple of the element before, at, and after the index
- Code like this should run:

  <Transclude src={exerciseStrangeIndexing} focusOn="16+5" />

## Bonus

- Write this by only calling safe functions

---

# One answer

<Transclude src={exerciseStrangeIndexing} />

---

# One answer

import exerciseStrangeIndexingRev1 from "rust:ffi/exercise-strange-indexing?rev=1";

<Transclude src={exerciseStrangeIndexingRev1} />

---

# The Foreign Function Interface (FFI)

- Allows leveraging entire C ecosystem
- As well as any other language with a C API

<Notes />

- C is the Lingua Franca

---

<SlideLayout use={Splash} />

# _The Rust FFI Omnibus_

http://jakegoulding.com/rust-ffi-omnibus/

---

# Module structure

```rust
mod awesome {
    mod ffi {
        // Declarations of the C functions
    }

    // Rust abstractions on top of the C functions
}

// Usage of the Rust abstractions
```

<Notes />

- Using the module system and visibility to our advantage
- In real code, would recommend putting the `ffi` module in a separate crate
- Has to do with single linking of a C library

---

# `extern` blocks

```rust
extern "C" {
    pub fn awesome_version() -> c_int;
}
```

<Notes />

- Declares external functions / arguments / return types
- Technically the `"C"` isn't needed, but it's recommended

---

# Only certain types are valid to pass across FFI

- Have been annotated with `#[repr(C)]`.
- libc crate provides appropriate type aliases for the current platform

<Notes />

- Also declares the functions from the C library

---

# C strings

- `char *`
- Undefined encoding, but UTF-8 (or ASCII) is common on macOS and Linux
- NUL-terminated

<Continue />

# Rust strings

- `&str`, `String`
- Encoded in UTF-8
- May contain embedded NULs

---

# Converting strings

- `std::ffi::CStr`
- Can convert a `char *` to a `&str`.

---

# Is that a `char *` or a `char *`?

- Should you free a `char *`?

<Continue />

- While it's annoying that Rust has multiple string types, it's better than the alternative

---

# Exercise: Write a Rust binding for `awesome_features_enabled`

- https://gitlab.com/integer32llc/ffi-training-code/

- Add the appropriate `extern` function definition (and any needed types)
- Write a safe Rust wrapper and print out the value
- Call the wrapper from main

<Notes />

- How long is the string valid for?
- Touch on lifetime ambiguity

---

# One answer

- Available at tag [step-1][] ([diff][step-1-diff])

[step-1]: https://gitlab.com/integer32llc/ffi-training-code/-/blob/step-1/src/main.rs
[step-1-diff]: https://gitlab.com/integer32llc/ffi-training-code/-/compare/step-0...step-1

---

# Casting between primitive types

- `as` keyword
- between integers and floats
- from `bool` and `char` to integers
- between pointers and pointers
- between pointers and addresses
- C-style enums to integers

---

# C-style enums

```rust
enum Days {
    Monday = 1,
    Tuesday = 2,
}
```

```rust
Days::Monday as u8
```

---

# Exercise: Write a Rust binding for `awesome_greeting`

- Add the appropriate `extern` function definition (and any needed types)
- Write a safe Rust wrapper and _return_ the value
- Print the value from main

## Hints

- Don't leak any memory

---

# One answer

- Available at tag [step-2][] ([diff][step-2-diff])

[step-2]: https://gitlab.com/integer32llc/ffi-training-code/-/blob/step-2/src/main.rs
[step-2-diff]: https://gitlab.com/integer32llc/ffi-training-code/-/compare/step-1...step-2

---

# `*mut T` vs `*const T`

- Unlike references (`&T`, `&mut T`), these are more what you'd call "guidelines" than actual rules
- Can cast back and forth with `as`
- Recommend using the type that's closer to your intention

---

# Opaque types / pointers

- Common practice to hide details of implementation
- Often done via _opaque pointer_
- Often something like

  ```c
  typedef struct awesome_type awesome_type_t;
  ```

---

# Opaque types in Rust

- Using the type system is wonderful
- Not a great solution today
- Represented as an non-creatable, zero-sized, `repr(C)` type:

  ```rust
  #[repr(C)]
  pub struct MyOpaqueType {
      _private: [i32; 0],
  }
  ```

- In the future, will have nice syntax:

  ```rust
  extern "C" {
      type MyOpaqueType;
  }
  ```

- Use this type in the FFI signatures as a pointer

---

# Drop

- Responsible for cleanup of a type when it goes out of scope
- It's a trait, but a little special treatment by the compiler

  ```rust
  impl Drop for MyType {
      fn drop(&mut self);
  }
  ```

---

# Leaking memory is _safe_

- It's not expected that a value will be leaked, but it's possible.
- Memory safety should never rely on `Drop` being run

---

# Exercise: Write a Rust binding for `awesome_new` and `awesome_free`

- Add the appropriate `extern` function definitions (and any needed types)
- Write a safe Rust type that calls `awesome_new` and `awesome_free` appropriately
- Construct this type from main

---

# One answer

- Available at tag [step-3][] ([diff][step-3-diff])

[step-3]: https://gitlab.com/integer32llc/ffi-training-code/-/blob/step-3/src/main.rs
[step-3-diff]: https://gitlab.com/integer32llc/ffi-training-code/-/compare/step-2...step-3

---

# Lifetimes and FFI

- Most C code doesn't state lifetimes
- In many cases, a pointer lasts as long as the object
- Same as equivalent Rust code:

  ```rust
  fn foo<(&self) -> &SomeType { /* ... */ }
  // Same as
  fn foo<'a>(&'a self) -> &'a SomeType { /* ... */ }
  ```

- Mutating the object may invalidate the pointer!

---

# Exercise: Write a Rust binding for `awesome_value`

- Add the appropriate `extern` function definitions (and any needed types)
- Add a safe method to the Rust type that calls `awesome_value` appropriately
- Call the method in main, print out the value, change the value, print it out again

---

# One answer

- Available at tag [step-4][] ([diff][step-4-diff])

[step-4]: https://gitlab.com/integer32llc/ffi-training-code/-/blob/step-4/src/main.rs
[step-4-diff]: https://gitlab.com/integer32llc/ffi-training-code/-/compare/step-3...step-4

---

# Multiple return values

- C has no concept of this
- Instead, pass in one or more pointer
- Sometimes called "out params"

---

# Uninitialized data

- C allows variables to be uninitialized:

  ```c
  int foo;
  ```

- If needed in Rust, can use `mem::MaybeUninit`
- Highly and subtly unsafe
- If possible, initialize values anyway.

---

# C arrays

- C does not have a standard way of passing arrays of data.
- Usually a pointer and a length
- Sometimes sentinel-terminated

---

# Constructing Slices

<SliceFromRawParts />

<SliceFromRawPartsMut />

---

# Exercise: Write a Rust binding for `awesome_values`

- Add the appropriate `extern` function definitions (and any needed types)
- Add a safe method to the Rust type that calls `awesome_values` appropriately
- Call the method in main, print out the sum of all the values, change the value, print it out again

---

# One answer

- Available at tag [step-5][] ([diff][step-5-diff])

[step-5]: https://gitlab.com/integer32llc/ffi-training-code/-/blob/step-5/src/main.rs
[step-5-diff]: https://gitlab.com/integer32llc/ffi-training-code/-/compare/step-4...step-5

---

# Tools

- [bindgen](https://rust-lang.github.io/rust-bindgen/)

  - Automatically generates Rust FFI bindings to C (and some C++) libraries.

- [cbindgen](https://github.com/mozilla/cbindgen)

  - A project for generating C bindings from Rust code

- [c2rust](https://c2rust.com)

  - C to Rust translation, refactoring, and cross-checking

---

# Language-specific tools

- [CXX](https://cxx.rs)
- [NAPI-RS](https://napi.rs)
- [PyO3](https://pyo3.rs/)
- [rustler](https://docs.rs/crate/rustler/latest)

<Notes />

- When you have a fixed target, you can usually get a tighter integration

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
