import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";

import { DeriveInput, Data, DataStruct } from "./Excerpts";

export const title = "Macros";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Macros

---

# Types of macros

- Declarative
- Procedural

---

# Declarative

- Defined inside your code
- Match on the structure of the arguments
  - abstract syntax tree (AST)
- Hygenic

---

# Why use a macro?

import oldTry from "rust:macros/old-try";

<Transclude src={oldTry} focusOn="1+8" />

<Notes />

- Allow you to construct new types of syntax.
- Before `?`, there was a macro called `try`

---

# Macro hello world

import helloWorld from "rust:macros/hello-world";

<Transclude src={helloWorld} />

---

# Macro hello world

<Transclude src={helloWorld} emphasize="1[0+12]" />

---

# Macro hello world

<Transclude src={helloWorld} emphasize="1[13+7]" />

---

# Macro hello world

<Transclude src={helloWorld} emphasize="2,3,4" />

---

# Macro hello world

<Transclude src={helloWorld} emphasize="4[5+1]" />

---

# Macro arguments

import args from "rust:macros/arguments";

<Transclude src={args} />

---

# Macro arguments

<Transclude src={args} emphasize="2[5+2],5[5+3],11[13+2],12[13+3]" />

<Notes />

- Can use various literal tokens to select which macro arm

---

# Macro patterns

import patterns from "rust:macros/patterns";

<Transclude src={patterns} />

---

# Macro patterns

<Transclude src={patterns} emphasize="2[5+4],3[17+4]" />

<Notes />

- Variables are prefixed with a dollar sign.

---

# Macro patterns

<Transclude src={patterns} emphasize="2[11+4]" />

<Notes />

- "type" is different; corresponds to things that can be parsed

---

# Macro fragment specifiers

- `expr`: an expression
- `ident`: an identifier
- `ty`: a type
- `item`: an item, like a function, struct, module, etc.
- [more](https://doc.rust-lang.org/1.71.0/reference/macros-by-example.html#metavariables)

<Notes />

- These are just a sample of some selectors

---

# Valid macro locations

- Expressions / Statements
- Items
- `impl` Items
- Types
- Patterns

---

# Macros allow for repetition

import repetition from "rust:macros/repetition";

<Transclude src={repetition} />

---

# Macros allow for repetition

<Transclude src={repetition} emphasize="4[15+2],4[28+3],6[21+2],6[28+3]" />

<Notes />

- Separator can be `,` / `;` / `=>`
- Repetition can be `*` / `+` / `?`
- Many macros use recursive logic like this

---

# Matched pairs

- Macro can be called with `[]`, `()`, or `{}`
- Must always be correctly nested and balanced

<Notes />

- Must always generate correct code; nothing broken

---

# Exercise: macro

import exerciseMacro from "rust:macros/exercise-macro";

- Create a macro that takes a number and an expression
- Runs the expression number of times
- Code like this should run:

  <Transclude src={exerciseMacro} focusOn="10" />

## Hint

- How would you write this code _without_ a macro?

---

# One answer

<Continue />

<Transclude src={exerciseMacro} />

<Notes />

- counting with macros isn't really possible, so a recursive solution can't work here

---

# Procedural

- More complicated logic
- Macro is given an input stream and returns a stream
- Parse input manually from the stream
- Implementation is in a separate crate

---

# Common use case: deriving trait implementations

import serialize from "rust:macros/serialize";

export const SerializePrelude = () => (
  <Transclude
    src={serialize}
    focusOn="1+5,10,15"
    transform={{
      5: [/$/, " /* ... */ }"],
      6: [/$/, " /* ... */ }"],
      7: [/$/, " /* ... */ }"],
    }}
  />
);

<SerializePrelude />

<Transclude src={serialize} focusOn="21+5" />

<Continue />

<Transclude src={serialize} focusOn="27+9" />

---

# Common use case: deriving trait implementations

import serializeRev1 from "rust:macros/serialize?rev=1";

<SerializePrelude />

<Transclude src={serializeRev1} focusOn="23+6" emphasize="1" />

---

# Syn

<DeriveInput />

<Data />

<DataStruct />

<Notes />

- Most common way to parse Rust code
- The entire Rust AST is exposed as structs and enums
- Can be tedious to pick through, but sure to not forget a case

---

# Quote

import serializeDerive from "rust:macros/serialize/serialize-derive/src/lib.rs";

<Transclude src={serializeDerive} focusOn="25-33" />

---

# Quote

<Transclude
  src={serializeDerive}
  focusOn="25-33"
  emphasize="2[23],5[12],5[29]"
/>

<Notes />

- Uses `#` instead of `$`
- Quote is black magic, as far as I'm concerned

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
